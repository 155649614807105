<template>
	<div>
		<v-row dense>
			<v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
				<div class="d-flex justify-space-between align-center mb-2 mt-4">
					<div class="input-wrap">
						<h6 class="mb-2">Nuevo comprobante</h6>
					</div>
					<div class="action-btn-wrap">
						<v-btn color="secondary" :to="{ name: 'DocumentIndex' }">Ir a la lista</v-btn>
					</div>
				</div>
				<div class="scroll-area" style="height: calc(100vh - 135px);">
					<invoice></invoice>
				</div>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import Invoice from './Invoice';

export default {
	components: {
		Invoice,
	},
};
</script>
